import http from '@/shared/http'
import apiResultWrapper from '@/shared/apiResultWrapper'

const API = '/api/v1/reports/eze'

// fetch information about blotter accounts - in BE is organize structure of users
const fetchAccounts = async () => apiResultWrapper.apiResult(await http.get(`${API}/accounts`))
// fetch available report in prop reports
const fetchAvailableReports = async () => apiResultWrapper.apiResult(await http.get(`${API}/available-reports`))

export default {
  fetchAccounts,
  fetchAvailableReports
}
