<template>
  <v-container
    id="reports-container"
    fluid
    tag="section"
    class="full-height"
  >
    <v-row>
      <v-col cols="3">
        <v-autocomplete
          v-model="selectedAccessId"
          :disabled="loading"
          :items="accounts"
          color="primary"
          auto-select-first
          label="Select Account"
          item-text="name"
          item-value="accessId"
        />
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="selectedReport"
          :disabled="loading"
          :items="availableReports"
          color="primary"
          auto-select-first
          label="Select Account"
          item-text="label"
          item-value="value"
        />
      </v-col>
      <v-col cols="2">
        <v-dialog
          ref="from"
          v-model="search.fromModel"
          :return-value.sync="search.from"
          :disabled="loading"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="search.from"
              label="Date From"
              prepend-icon="mdi-calendar"
              :disabled="loading"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="search.from"
            :max="search.to"
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="search.fromModel = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.from.save(search.from)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="2">
        <v-dialog
          ref="to"
          v-model="search.toModel"
          :return-value.sync="search.to"
          :disabled="loading"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="search.to"
              :disabled="loading"
              label="Date To"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="search.to"
            :min="search.from"
            :max="maxDay"
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="search.toModel = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.to.save(search.to)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col
        cols="2"
        class="text-right"
      >
        <v-btn
          :loading="loading"
          :disabled="loading"
          color="primary"
          class="white--text btn-center"
          x-large
          @click="fetchReport()"
        >
          Search
          <v-icon
            dark
            right
          >
            mdi-magnify
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      LOADING
    </v-row>
    <v-row v-else v-html="report">
    </v-row>
  </v-container>
</template>
<script>
import ezeReportService from '@/service/EzeReportsService'
import sterlingReportService from '@/service/SterlingReportsService'

import axios from 'axios'

export default {
  name: 'Reports',
  components: {
  },
  data: () => ({
    selectedAccessId: 0,
    selectedReport: '',
    availableReports: [
    ],
    accounts: [],
    loading: false,
    search: {
      from: '',
      to: '',
      fromModel: false,
      toModel: false
    },
    report: ''
  }),
  watch: {
    async selectedAccessId (val) {
      this.selectedUserId = this.accounts.filter(i => i.accessId === val)[0].userId
    }
  },
  computed: {
    maxDay: () => {
      return new Date().toISOString().substr(0, 10)
    }
  },
  beforeMount () {
    this.fetchAccounts()
    this.fetchAvailableReports()
  },
  methods: {
    reportService () {
      switch (this.$route.params.category) {
        case 'sterling-reports': return sterlingReportService
        case 'eze-reports': return ezeReportService
        default: return ezeReportService
      }
    },
    reportContextPath () {
      switch (this.$route.params.category) {
        case 'sterling-reports': return 'sterling'
        case 'eze-reports': return 'eze'
        default: return 'eze'
      }
    },
    async fetchAccounts () {
      this.loading = true
      const response = await this.reportService().fetchAccounts()
      if (response.status === 200) {
        this.accounts = response.data
        this.selectedAccessId = this.accounts[0].accessId
      }
      this.loading = false
    },
    async fetchAvailableReports () {
      this.loading = true
      const response = await this.reportService().fetchAvailableReports()
      if (response.status === 200) {
        this.availableReports = response.data
        this.selectedReport = this.availableReports[0].value
      }
      this.loading = false
    },
    async fetchReport () {
      this.loading = true

      // request
      let query = `report-type=${this.selectedReport}`
      if (this.search.from) {
        query += `&from=${new Date(this.search.from).getTime()}`
      }
      if (this.search.to) {
        query += `&to=${new Date(this.search.to).getTime()}`
      }
      if (this.selectedAccessId) {
        query += `&accessId=${this.selectedAccessId}`
      }
      axios.get(`/api/v1/reports/${this.reportContextPath()}/fetch-report?${query}`)
        .then(r => {
          this.report = r.data
          this.loading = false
        })
        .catch(e => {
          this.report = ''
          this.loading = false
        })
    }
  }
}
</script>
<style>

/* Tables / Report Body - Used in all reports
-------------------------------------------------------------- */

TABLE.report {
  background-color: #fff;
  border: 1px solid #a0aabe;
  width: 100%;
  margin-top: 2rem;
  border-radius: 5px;
  /*font-family: cursive;*/
  letter-spacing: 0.75px;
  font-family: Verdana;
  font-weight: 300;
}

TABLE.report thead th {
  text-align: left;
  padding-left: 16px;
  background: #f5f5f5 none;
  color: #a50d0d;
  font: bold 14px/30px Verdana, Arial, sans-serif;
}

TABLE.report TD, TABLE.report TH {
  padding: 2px;
  white-space: nowrap;
  vertical-align: middle;
}

TABLE.report TH {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

TABLE.report TD {
  text-align: right;
  background-color: #e5e5e5;
  vertical-align: top;
  padding: 1px;
  padding-right: 1px;
}

TABLE.report TD.text, TABLE.report TH.text {
  text-align: right;
}

TABLE.report TD.exacttext {
  text-align: left;
  white-space: pre;
}

TABLE.report TD.massUpdateForm, TABLE.report TH.massUpdateForm {
  text-align: left;
  vertical-align: middle;
}

TABLE.report TD.wrappingtext{
  text-align: left;
  white-space: normal;
}

TABLE.report TD.exactflag {
  text-align: center;
  white-space: pre;
}

TABLE.report TD.time, TABLE.report TD.flag {
  text-align: center;
}

TABLE.report TD.border {
  background-color: #a0aabe;
  padding: 0;
  height: 1px;
}

TABLE.report TD.spacer {
  background-color: #333;
  padding: 0;
  height: 1px;
}

TABLE.report TR.rule TD {
  height: 2px;
  padding: 0;
  margin: 0;
}

TABLE.report TR.summary TH {
  background-color: #d5d5e5;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 1px;
}

TABLE.report TR.detail TH {
  background-color: #e5e5e5;
}

TABLE.report TR.subtotal TD {
  font-weight: bold;
}

TABLE.report TR.total TH {
  background-color: #ccc;
}

TABLE.report TR.total TD {
  background-color: #d5d5d5;
  font-weight: bold;
  font-size: 1rem;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 1px;
}

TABLE.report TR.sectionSeparator TD {
  background-color: #fff;
  color: #036;
  padding-top: 1px;
  text-align: left;
  padding-bottom: 1px;
  padding-left: 1px;
  font-size: 1rem;
  font-style: italic;
  font-weight: 600;
}

TABLE.report TR.tab TD {
  border-left: 0 solid #fff;
  background: #fff;
}

TABLE.report TR.tab TD.activeTab {
  background: #369;
  font-weight: bold;
  font-size: 12px;
  color: #fff;
  text-align: center;
}

TABLE.report TD.discrepancy {
  background-color: #fce9ea;
}

TABLE.report TD.noDiscrepancy {
  background-color: #e5eedf;
}

TR.loss TD, TABLE.report TD.loss {
  background-color: #fce9ea;
}

TABLE.report TD.merge-conflict{
  background-color: #fce9ea;
  font-weight: bold;
}

TR.win TD, TR.currentPlan TD, TABLE.report TD.win {
  background-color: #e5eedf;
}

TR.wash TD {
  background-color: #f5f5f5;
}

TR.weekend TD, TR.disabled TD {
  background-color: #FCE9EA;
}

TR.incomplete TD, TR.noPlan TD {
  background-color: #f9d4d8;
}

TR.enabled TD {
  background-color: #e5eedf;
}

TD.redBoldText {
  color: #c03;
  font-weight: bold;
}

TR.inactive TD, TR.inactive-summary TD {
  font-style: italic;
}

TABLE.report TD.bad {
  background-color: #ffA5A5;
}

TABLE.report TD.good {
  background-color: #e5eedf;
}

TABLE.report text {
  text-align: right;
}

TABLE.autowidth {
  width: auto;
}

TABLE.report TR.transferAdjustment TD {
  background-color: #DDE6EE;
}

Table span.tooltip {
  display : none;
}

TABLE.report a {
  color: black !important;
}

</style>
